import PropTypes from 'prop-types';
import { uniqueId } from 'lodash-es';

const propTypes = { blocks: PropTypes.arrayOf(PropTypes.string).isRequired };
const defaultProps = {};

function CommentSourceText({ blocks }) {
  return (
    <div className="comment-reply-source d-flex flex-column flex-gap-lg">
      {blocks.map((block) => (
        <span className="d-block text-muted" key={uniqueId('comment-reply-source-')}>
          {block}
        </span>
      ))}
    </div>
  );
}

CommentSourceText.propTypes = propTypes;
CommentSourceText.defaultProps = defaultProps;

export default CommentSourceText;
